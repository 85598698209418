import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { ERROR_TRANSLATOR } from '@x/common/error';
import { GRAPH_CLIENT, GraphNamedClientConfig } from '@x/common/graph';
import { EcommerceAuthTokenContextService } from './auth/auth-token-context.service';
import { EcommerceAuthTokenInterceptor } from './auth/auth-token.interceptor';
import { EcommerceDomainClientErrorTranslator } from './ecommerce-domain-client.error-translator';
import { AdService } from './services/ad.service';
import { AddressService } from './services/address.service';
import { AgreementDeclarationService } from './services/agreement-declarataion.service';
import { AgreementService } from './services/agreement.service';
import { AuthRoleService } from './services/auth-role.service';
import { AuthService } from './services/auth.service';
import { BulkOrderService } from './services/bulk-order.service';
import { CartService } from './services/cart.service';
import { ChannelService } from './services/channel.service';
import { CollectionPointService } from './services/collection-point.service';
import { ContentService } from './services/content.service';
import { CurrencyService } from './services/currency.service';
import { FulfilmentIntervalService } from './services/fulfilment-interval.service';
import { GeoRegionService } from './services/geo-region.service';
import { GoogleProductTypeService } from './services/google-product-type.service';
import { MediaService } from './services/media.service';
import { MemberService } from './services/member.service';
import { MembershipService } from './services/membership.service';
import { NotificationLogService } from './services/notification-log.service';
import { OrderIncidentCategoryService } from './services/order-incident-category.service';
import { OrderIncidentService } from './services/order-incident.service';
import { OrderItemService } from './services/order-item.service';
import { OrderTagService } from './services/order-tag.service';
import { OrderTemplateService } from './services/order-template.service';
import { OrderService } from './services/order.service';
import { ParcelOptionService } from './services/parcel-option.service';
import { PaymentMethodService } from './services/payment-method.service';
import { PaymentProviderService } from './services/payment-provider.service';
import { PaymentService } from './services/payment.service';
import { ProductAssociationService } from './services/product-association.service';
import { ProductAttributeService } from './services/product-attribute.service';
import { ProductOptionService } from './services/product-option.service';
import { ProductVariantService } from './services/product-variant.service';
import { ProductWishlistItemService } from './services/product-wishlist-item.service';
import { ProductService } from './services/product.service';
import { PromotionService } from './services/promotion.service';
import { ReportService } from './services/report.service';
import { ShipmentService } from './services/shipment.service';
import { ShippingCategoryService } from './services/shipping-category.service';
import { ShippingMethodService } from './services/shipping-method.service';
import { ShippingScheduleService } from './services/shipping-schedule.service';
import { ShippingSlotService } from './services/shipping-slot.service';
import { StockAllocationService } from './services/stock-allocation.service';
import { StockAvailabilityService } from './services/stock-availability.service';
import { StockCategoryService } from './services/stock-category.service';
import { StockItemComponentService } from './services/stock-item-component.service';
import { StockItemService } from './services/stock-item.service';
import { StockLotService } from './services/stock-lot.service';
import { StockScheduleService } from './services/stock-schedule.service';
import { SubscriberTagService } from './services/subscriber-tag.service';
import { SubscriberService } from './services/subscriber.service';
import { SubscriptionEntryService } from './services/subscription-entry.service';
import { SubscriptionMembershipBenefitService } from './services/subscription-membership-benefit.service';
import { SubscriptionRunService } from './services/subscription-run.service';
import { SubscriptionVariantProductQuantityService } from './services/subscription-variant-product-quantity.service';
import { SubscriptionVariantService } from './services/subscription-variant.service';
import { SubscriptionService } from './services/subscription.service';
import { TaxCategoryService } from './services/tax-category.service';
import { TaxRateService } from './services/tax-rate.service';
import { TaxonService } from './services/taxon.service';
import { UserAddressService } from './services/user-address.service';
import { UserPaymentMethodsService } from './services/user-payment-methods.service';
import { UserService } from './services/user.service';
import { VariableService } from './services/variable.service';
import { VoucherProgramService } from './services/voucher-program.service';
import { VoucherService } from './services/voucher.service';
import { WalletTransactionService } from './services/wallet-transaction.service';
import { WalletService } from './services/wallet.service';
import { WarehouseService } from './services/warehouse.service';

export interface EcommerceDomainClientModuleConfig {
  graphUrl: string;
}

@NgModule({})
export class EcommerceDomainClientModule {
  static forRoot(
    config: EcommerceDomainClientModuleConfig,
  ): ModuleWithProviders<EcommerceDomainClientModule> {
    const clientConfig: GraphNamedClientConfig = {
      name: 'ecommerce',
      uri: config.graphUrl,
      defaultOptions: {
        query: { fetchPolicy: 'no-cache', errorPolicy: 'none', returnPartialData: false },
      },
      retry: {
        enabled: true,
        queries: {
          maxRetries: 4,
          retryOnStatus: [0, 429, 503],
        },
        mutations: {
          maxRetries: 2,
          retryOnStatus: [0, 503],
        },
      },
    };

    return {
      ngModule: EcommerceDomainClientModule,
      providers: [
        AuthService,
        AgreementService,
        AgreementDeclarationService,
        EcommerceAuthTokenContextService,
        AuthRoleService,
        AddressService,
        CartService,
        ChannelService,
        ContentService,
        CurrencyService,
        GeoRegionService,
        OrderService,
        ParcelOptionService,
        PaymentMethodService,
        PaymentProviderService,
        PaymentService,
        ProductAssociationService,
        ProductAttributeService,
        ProductOptionService,
        ProductVariantService,
        ProductService,
        ProductWishlistItemService,
        PromotionService,
        ShipmentService,
        ShippingMethodService,
        StockCategoryService,
        StockScheduleService,
        StockItemService,
        StockItemComponentService,
        StockLotService,
        StockAllocationService,
        TaxCategoryService,
        TaxRateService,
        TaxonService,
        UserAddressService,
        UserPaymentMethodsService,
        UserService,
        WarehouseService,
        StockAvailabilityService,
        VoucherProgramService,
        VoucherService,
        MembershipService,
        MemberService,
        SubscriptionService,
        SubscriptionVariantService,
        SubscriptionVariantProductQuantityService,
        SubscriptionMembershipBenefitService,
        SubscriberService,
        SubscriberTagService,
        WalletService,
        WalletTransactionService,
        VariableService,
        SubscriptionRunService,
        SubscriptionEntryService,
        ShippingSlotService,
        FulfilmentIntervalService,
        OrderTagService,
        CollectionPointService,
        ShippingCategoryService,
        ShippingScheduleService,
        GoogleProductTypeService,
        OrderItemService,
        BulkOrderService,
        OrderTemplateService,
        ReportService,
        NotificationLogService,
        MediaService,
        OrderIncidentCategoryService,
        OrderIncidentService,
        AdService,
        {
          provide: ERROR_TRANSLATOR,
          useClass: EcommerceDomainClientErrorTranslator,
          multi: true,
        },
        {
          provide: HTTP_INTERCEPTORS,
          useClass: EcommerceAuthTokenInterceptor,
          multi: true,
        },
        {
          provide: GRAPH_CLIENT,
          useValue: clientConfig,
          multi: true,
        },
      ],
    };
  }
}
