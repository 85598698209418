import * as Types from '@x/schemas/ecommerce';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type StockLotItemFragment = { __typename: 'StockLotObject', id: string, priority: number, tracked: boolean, quantity: number, initialQuantity: number, reservedQuantity: number, allocatedQuantity: number, releasedQuantity: number, availableQuantity?: number | null | undefined, availableAt?: string | null | undefined, expiresAt?: string | null | undefined, overrides?: Array<string> | null | undefined, createdAt: any, updatedAt: any, enabled: boolean, schedule?: { __typename: 'StockScheduleObject', id: number, name: string, interval: Types.ScheduleInterval, days: string, enabled: boolean, startAt?: string | null | undefined, endAt?: string | null | undefined, tracked: boolean, shelfLife?: any | null | undefined, quantity: number, priority: number } | null | undefined, warehouse: { __typename: 'WarehouseObject', id: number, name: string, timezone: string } };

export type StockLotRowFragment = { __typename: 'StockLotObject', id: string, priority: number, tracked: boolean, quantity: number, initialQuantity: number, reservedQuantity: number, allocatedQuantity: number, releasedQuantity: number, availableQuantity?: number | null | undefined, availableAt?: string | null | undefined, expiresAt?: string | null | undefined, createdAt: any, updatedAt: any, enabled: boolean, overrides?: Array<string> | null | undefined, schedule?: { __typename: 'StockScheduleObject', id: number, name: string, interval: Types.ScheduleInterval, days: string, enabled: boolean, startAt?: string | null | undefined, endAt?: string | null | undefined, tracked: boolean, shelfLife?: any | null | undefined, quantity: number, priority: number } | null | undefined, warehouse: { __typename: 'WarehouseObject', id: number, name: string, timezone: string } };

export type StockLotDetailFragment = { __typename: 'StockLotObject', id: string, priority: number, tracked: boolean, quantity: number, initialQuantity: number, reservedQuantity: number, allocatedQuantity: number, releasedQuantity: number, availableQuantity?: number | null | undefined, availableAt?: string | null | undefined, expiresAt?: string | null | undefined, createdAt: any, updatedAt: any, enabled: boolean, item: { __typename: 'StockItemObject', id: number, sku: string, name: string }, schedule?: { __typename: 'StockScheduleObject', id: number, name: string, interval: Types.ScheduleInterval, days: string, enabled: boolean, startAt?: string | null | undefined, endAt?: string | null | undefined, tracked: boolean, shelfLife?: any | null | undefined, quantity: number, priority: number } | null | undefined, warehouse: { __typename: 'WarehouseObject', id: number, name: string, timezone: string } };

export type StockLotDetailQueryVariables = Types.Exact<{
  id: Types.Scalars['String']['input'];
}>;


export type StockLotDetailQuery = { __typename: 'Query', stockLot: { __typename: 'StockLotObject', id: string, priority: number, tracked: boolean, quantity: number, initialQuantity: number, reservedQuantity: number, allocatedQuantity: number, releasedQuantity: number, availableQuantity?: number | null | undefined, availableAt?: string | null | undefined, expiresAt?: string | null | undefined, createdAt: any, updatedAt: any, enabled: boolean, item: { __typename: 'StockItemObject', id: number, sku: string, name: string }, schedule?: { __typename: 'StockScheduleObject', id: number, name: string, interval: Types.ScheduleInterval, days: string, enabled: boolean, startAt?: string | null | undefined, endAt?: string | null | undefined, tracked: boolean, shelfLife?: any | null | undefined, quantity: number, priority: number } | null | undefined, warehouse: { __typename: 'WarehouseObject', id: number, name: string, timezone: string } } };

export type StockLotRowQueryVariables = Types.Exact<{
  id: Types.Scalars['String']['input'];
}>;


export type StockLotRowQuery = { __typename: 'Query', stockLot: { __typename: 'StockLotObject', id: string, priority: number, tracked: boolean, quantity: number, initialQuantity: number, reservedQuantity: number, allocatedQuantity: number, releasedQuantity: number, availableQuantity?: number | null | undefined, availableAt?: string | null | undefined, expiresAt?: string | null | undefined, createdAt: any, updatedAt: any, enabled: boolean, overrides?: Array<string> | null | undefined, schedule?: { __typename: 'StockScheduleObject', id: number, name: string, interval: Types.ScheduleInterval, days: string, enabled: boolean, startAt?: string | null | undefined, endAt?: string | null | undefined, tracked: boolean, shelfLife?: any | null | undefined, quantity: number, priority: number } | null | undefined, warehouse: { __typename: 'WarehouseObject', id: number, name: string, timezone: string } } };

export type StockLotsQueryVariables = Types.Exact<{
  filter: Types.StockLotFilterInput;
  sort?: Types.InputMaybe<Types.SortInput>;
  page?: Types.InputMaybe<Types.PageInput>;
}>;


export type StockLotsQuery = { __typename: 'Query', totalItemsCount: number, items: Array<{ __typename: 'StockLotObject', id: string, priority: number, tracked: boolean, quantity: number, initialQuantity: number, reservedQuantity: number, allocatedQuantity: number, releasedQuantity: number, availableQuantity?: number | null | undefined, availableAt?: string | null | undefined, expiresAt?: string | null | undefined, overrides?: Array<string> | null | undefined, createdAt: any, updatedAt: any, enabled: boolean, schedule?: { __typename: 'StockScheduleObject', id: number, name: string, interval: Types.ScheduleInterval, days: string, enabled: boolean, startAt?: string | null | undefined, endAt?: string | null | undefined, tracked: boolean, shelfLife?: any | null | undefined, quantity: number, priority: number } | null | undefined, warehouse: { __typename: 'WarehouseObject', id: number, name: string, timezone: string } }> };

export type CreateStockLotMutationVariables = Types.Exact<{
  input: Types.CreateStockLotInput;
}>;


export type CreateStockLotMutation = { __typename: 'Mutation', createStockLot: { __typename: 'StockLotObject', id: string, priority: number, tracked: boolean, quantity: number, initialQuantity: number, reservedQuantity: number, allocatedQuantity: number, releasedQuantity: number, availableQuantity?: number | null | undefined, availableAt?: string | null | undefined, expiresAt?: string | null | undefined, overrides?: Array<string> | null | undefined, createdAt: any, updatedAt: any, enabled: boolean, schedule?: { __typename: 'StockScheduleObject', id: number, name: string, interval: Types.ScheduleInterval, days: string, enabled: boolean, startAt?: string | null | undefined, endAt?: string | null | undefined, tracked: boolean, shelfLife?: any | null | undefined, quantity: number, priority: number } | null | undefined, warehouse: { __typename: 'WarehouseObject', id: number, name: string, timezone: string } } };

export type UpdateStockLotMutationVariables = Types.Exact<{
  input: Types.UpdateStockLotInput;
}>;


export type UpdateStockLotMutation = { __typename: 'Mutation', updateStockLot: { __typename: 'StockLotObject', id: string, priority: number, tracked: boolean, quantity: number, initialQuantity: number, reservedQuantity: number, allocatedQuantity: number, releasedQuantity: number, availableQuantity?: number | null | undefined, availableAt?: string | null | undefined, expiresAt?: string | null | undefined, overrides?: Array<string> | null | undefined, createdAt: any, updatedAt: any, enabled: boolean, schedule?: { __typename: 'StockScheduleObject', id: number, name: string, interval: Types.ScheduleInterval, days: string, enabled: boolean, startAt?: string | null | undefined, endAt?: string | null | undefined, tracked: boolean, shelfLife?: any | null | undefined, quantity: number, priority: number } | null | undefined, warehouse: { __typename: 'WarehouseObject', id: number, name: string, timezone: string } } };

export type UpdateEnabledStockLotMutationVariables = Types.Exact<{
  ref: Types.Scalars['String']['input'];
  enabled: Types.Scalars['Boolean']['input'];
}>;


export type UpdateEnabledStockLotMutation = { __typename: 'Mutation', updateStockLotEnabled: { __typename: 'StockLotObject', id: string, priority: number, tracked: boolean, quantity: number, initialQuantity: number, reservedQuantity: number, allocatedQuantity: number, releasedQuantity: number, availableQuantity?: number | null | undefined, availableAt?: string | null | undefined, expiresAt?: string | null | undefined, overrides?: Array<string> | null | undefined, createdAt: any, updatedAt: any, enabled: boolean, schedule?: { __typename: 'StockScheduleObject', id: number, name: string, interval: Types.ScheduleInterval, days: string, enabled: boolean, startAt?: string | null | undefined, endAt?: string | null | undefined, tracked: boolean, shelfLife?: any | null | undefined, quantity: number, priority: number } | null | undefined, warehouse: { __typename: 'WarehouseObject', id: number, name: string, timezone: string } } };

export type AdjustStockLotMutationVariables = Types.Exact<{
  ref: Types.Scalars['String']['input'];
  adjustment: Types.Scalars['Int']['input'];
}>;


export type AdjustStockLotMutation = { __typename: 'Mutation', adjustStockLot: { __typename: 'StockLotObject', id: string, priority: number, tracked: boolean, quantity: number, initialQuantity: number, reservedQuantity: number, allocatedQuantity: number, releasedQuantity: number, availableQuantity?: number | null | undefined, availableAt?: string | null | undefined, expiresAt?: string | null | undefined, overrides?: Array<string> | null | undefined, createdAt: any, updatedAt: any, enabled: boolean, schedule?: { __typename: 'StockScheduleObject', id: number, name: string, interval: Types.ScheduleInterval, days: string, enabled: boolean, startAt?: string | null | undefined, endAt?: string | null | undefined, tracked: boolean, shelfLife?: any | null | undefined, quantity: number, priority: number } | null | undefined, warehouse: { __typename: 'WarehouseObject', id: number, name: string, timezone: string } } };

export const StockLotItemFragmentDoc = gql`
    fragment StockLotItem on StockLotObject {
  id
  priority
  tracked
  quantity
  initialQuantity
  reservedQuantity
  allocatedQuantity
  releasedQuantity
  availableQuantity
  availableAt
  expiresAt
  overrides
  createdAt
  updatedAt
  enabled
  schedule {
    id
    name
    interval
    days
    enabled
    startAt
    endAt
    tracked
    shelfLife
    quantity
    priority
  }
  warehouse {
    id
    name
    timezone
  }
}
    `;
export const StockLotRowFragmentDoc = gql`
    fragment StockLotRow on StockLotObject {
  id
  priority
  tracked
  quantity
  initialQuantity
  reservedQuantity
  allocatedQuantity
  releasedQuantity
  availableQuantity
  availableAt
  expiresAt
  createdAt
  updatedAt
  enabled
  overrides
  schedule {
    id
    name
    interval
    days
    enabled
    startAt
    endAt
    tracked
    shelfLife
    quantity
    priority
  }
  warehouse {
    id
    name
    timezone
  }
}
    `;
export const StockLotDetailFragmentDoc = gql`
    fragment StockLotDetail on StockLotObject {
  id
  priority
  tracked
  quantity
  initialQuantity
  reservedQuantity
  allocatedQuantity
  releasedQuantity
  availableQuantity
  availableAt
  expiresAt
  createdAt
  updatedAt
  enabled
  item {
    id
    sku
    name
  }
  schedule {
    id
    name
    interval
    days
    enabled
    startAt
    endAt
    tracked
    shelfLife
    quantity
    priority
  }
  warehouse {
    id
    name
    timezone
  }
}
    `;
export const StockLotDetailDocument = gql`
    query StockLotDetail($id: String!) {
  stockLot(id: $id) {
    ...StockLotDetail
  }
}
    ${StockLotDetailFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class StockLotDetailGQL extends Apollo.Query<StockLotDetailQuery, StockLotDetailQueryVariables> {
    document = StockLotDetailDocument;
    client = 'ecommerce';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const StockLotRowDocument = gql`
    query StockLotRow($id: String!) {
  stockLot(id: $id) {
    ...StockLotRow
  }
}
    ${StockLotRowFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class StockLotRowGQL extends Apollo.Query<StockLotRowQuery, StockLotRowQueryVariables> {
    document = StockLotRowDocument;
    client = 'ecommerce';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const StockLotsDocument = gql`
    query StockLots($filter: StockLotFilterInput!, $sort: SortInput, $page: PageInput) {
  items: stockLots(filter: $filter, sort: $sort, page: $page) {
    ...StockLotItem
  }
  totalItemsCount: stockLotsCount(filter: $filter)
}
    ${StockLotItemFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class StockLotsGQL extends Apollo.Query<StockLotsQuery, StockLotsQueryVariables> {
    document = StockLotsDocument;
    client = 'ecommerce';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateStockLotDocument = gql`
    mutation CreateStockLot($input: CreateStockLotInput!) {
  createStockLot(input: $input) {
    ...StockLotItem
  }
}
    ${StockLotItemFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateStockLotGQL extends Apollo.Mutation<CreateStockLotMutation, CreateStockLotMutationVariables> {
    document = CreateStockLotDocument;
    client = 'ecommerce';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateStockLotDocument = gql`
    mutation UpdateStockLot($input: UpdateStockLotInput!) {
  updateStockLot(input: $input) {
    ...StockLotItem
  }
}
    ${StockLotItemFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateStockLotGQL extends Apollo.Mutation<UpdateStockLotMutation, UpdateStockLotMutationVariables> {
    document = UpdateStockLotDocument;
    client = 'ecommerce';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateEnabledStockLotDocument = gql`
    mutation UpdateEnabledStockLot($ref: String!, $enabled: Boolean!) {
  updateStockLotEnabled(ref: $ref, enabled: $enabled) {
    ...StockLotItem
  }
}
    ${StockLotItemFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateEnabledStockLotGQL extends Apollo.Mutation<UpdateEnabledStockLotMutation, UpdateEnabledStockLotMutationVariables> {
    document = UpdateEnabledStockLotDocument;
    client = 'ecommerce';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const AdjustStockLotDocument = gql`
    mutation AdjustStockLot($ref: String!, $adjustment: Int!) {
  adjustStockLot(ref: $ref, adjustment: $adjustment) {
    ...StockLotItem
  }
}
    ${StockLotItemFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class AdjustStockLotGQL extends Apollo.Mutation<AdjustStockLotMutation, AdjustStockLotMutationVariables> {
    document = AdjustStockLotDocument;
    client = 'ecommerce';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }