import * as Types from '@x/schemas/ecommerce';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type StockAvailabilityIntervalQueryVariables = Types.Exact<{
  filter: Types.StockAvailabilityIntervalFilterInput;
}>;


export type StockAvailabilityIntervalQuery = { __typename: 'Query', stockAvailabilityInterval: Array<{ __typename: 'StockItemQuantityIntervalObject', date: string, availableQuantity?: number | null | undefined, reservedQuantity: number, allocatedQuantity: number }> };

export const StockAvailabilityIntervalDocument = gql`
    query StockAvailabilityInterval($filter: StockAvailabilityIntervalFilterInput!) {
  stockAvailabilityInterval(filter: $filter) {
    date
    availableQuantity
    reservedQuantity
    allocatedQuantity
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class StockAvailabilityIntervalGQL extends Apollo.Query<StockAvailabilityIntervalQuery, StockAvailabilityIntervalQueryVariables> {
    document = StockAvailabilityIntervalDocument;
    client = 'ecommerce';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }