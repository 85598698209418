import * as Types from '@x/schemas/ecommerce';

import { gql } from 'apollo-angular';
import { ShippingMethodItemFragmentDoc, ShippingMethodDetailFragmentDoc } from './shipping-method.gql.generated';
import { AddressFragmentDoc } from './address.gql.generated';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type CollectionPointItemFragment = { __typename: 'CollectionPointObject', id: number, name: string, code: string, enabled: boolean, createdAt: any, updatedAt: any, method: { __typename: 'ShippingMethodObject', id: number, name: string, code: string, slotRequired: boolean, collectionRequired: boolean, provider: string, requiresWaybill: boolean, timezone: string, color?: string | null | undefined, enabled: boolean } };

export type CollectionPointRowFragment = { __typename: 'CollectionPointObject', id: number, name: string, code: string, enabled: boolean, createdAt: any, updatedAt: any, method: { __typename: 'ShippingMethodObject', id: number, name: string, code: string, slotRequired: boolean, collectionRequired: boolean, provider: string, requiresWaybill: boolean, timezone: string, color?: string | null | undefined, enabled: boolean }, address: { __typename: 'AddressObject', id: number, alias?: string | null | undefined, businessName?: string | null | undefined, city?: string | null | undefined, complex?: string | null | undefined, coordinates?: any | null | undefined, country: string, email?: string | null | undefined, firstName?: string | null | undefined, instructions?: string | null | undefined, lastName?: string | null | undefined, phoneNumber?: string | null | undefined, postalCode?: string | null | undefined, province?: string | null | undefined, street?: string | null | undefined, suburb?: string | null | undefined, type: Types.AddressType, archivedAt?: any | null | undefined, hash: string } };

export type CollectionPointItemQueryVariables = Types.Exact<{
  id: Types.Scalars['Int']['input'];
}>;


export type CollectionPointItemQuery = { __typename: 'Query', collectionPoint: { __typename: 'CollectionPointObject', id: number, name: string, code: string, enabled: boolean, createdAt: any, updatedAt: any, method: { __typename: 'ShippingMethodObject', id: number, name: string, code: string, slotRequired: boolean, collectionRequired: boolean, provider: string, requiresWaybill: boolean, timezone: string, color?: string | null | undefined, enabled: boolean } } };

export type CollectionPointItemsQueryVariables = Types.Exact<{
  page?: Types.InputMaybe<Types.PageInput>;
  filter?: Types.InputMaybe<Types.CollectionPointFilterInput>;
  sort?: Types.InputMaybe<Types.SortInput>;
}>;


export type CollectionPointItemsQuery = { __typename: 'Query', totalItemsCount: number, items: Array<{ __typename: 'CollectionPointObject', id: number, name: string, code: string, enabled: boolean, createdAt: any, updatedAt: any, method: { __typename: 'ShippingMethodObject', id: number, name: string, code: string, slotRequired: boolean, collectionRequired: boolean, provider: string, requiresWaybill: boolean, timezone: string, color?: string | null | undefined, enabled: boolean } }> };

export type CollectionPointRowQueryVariables = Types.Exact<{
  id: Types.Scalars['Int']['input'];
}>;


export type CollectionPointRowQuery = { __typename: 'Query', collectionPoint: { __typename: 'CollectionPointObject', id: number, name: string, code: string, enabled: boolean, createdAt: any, updatedAt: any, method: { __typename: 'ShippingMethodObject', id: number, name: string, code: string, slotRequired: boolean, collectionRequired: boolean, provider: string, requiresWaybill: boolean, timezone: string, color?: string | null | undefined, enabled: boolean }, address: { __typename: 'AddressObject', id: number, alias?: string | null | undefined, businessName?: string | null | undefined, city?: string | null | undefined, complex?: string | null | undefined, coordinates?: any | null | undefined, country: string, email?: string | null | undefined, firstName?: string | null | undefined, instructions?: string | null | undefined, lastName?: string | null | undefined, phoneNumber?: string | null | undefined, postalCode?: string | null | undefined, province?: string | null | undefined, street?: string | null | undefined, suburb?: string | null | undefined, type: Types.AddressType, archivedAt?: any | null | undefined, hash: string } } };

export type CollectionPointRowsQueryVariables = Types.Exact<{
  page?: Types.InputMaybe<Types.PageInput>;
  filter?: Types.InputMaybe<Types.CollectionPointFilterInput>;
  sort?: Types.InputMaybe<Types.SortInput>;
}>;


export type CollectionPointRowsQuery = { __typename: 'Query', totalItemsCount: number, items: Array<{ __typename: 'CollectionPointObject', id: number, name: string, code: string, enabled: boolean, createdAt: any, updatedAt: any, method: { __typename: 'ShippingMethodObject', id: number, name: string, code: string, slotRequired: boolean, collectionRequired: boolean, provider: string, requiresWaybill: boolean, timezone: string, color?: string | null | undefined, enabled: boolean }, address: { __typename: 'AddressObject', id: number, alias?: string | null | undefined, businessName?: string | null | undefined, city?: string | null | undefined, complex?: string | null | undefined, coordinates?: any | null | undefined, country: string, email?: string | null | undefined, firstName?: string | null | undefined, instructions?: string | null | undefined, lastName?: string | null | undefined, phoneNumber?: string | null | undefined, postalCode?: string | null | undefined, province?: string | null | undefined, street?: string | null | undefined, suburb?: string | null | undefined, type: Types.AddressType, archivedAt?: any | null | undefined, hash: string } }> };

export type CollectionPointUpdateMutationVariables = Types.Exact<{
  data: Types.UpdateCollectionPointInput;
}>;


export type CollectionPointUpdateMutation = { __typename: 'Mutation', updateCollectionPoint: { __typename: 'CollectionPointObject', id: number, name: string, code: string, enabled: boolean, createdAt: any, updatedAt: any, method: { __typename: 'ShippingMethodObject', id: number, name: string, code: string, slotRequired: boolean, collectionRequired: boolean, provider: string, requiresWaybill: boolean, timezone: string, color?: string | null | undefined, enabled: boolean }, address: { __typename: 'AddressObject', id: number, alias?: string | null | undefined, businessName?: string | null | undefined, city?: string | null | undefined, complex?: string | null | undefined, coordinates?: any | null | undefined, country: string, email?: string | null | undefined, firstName?: string | null | undefined, instructions?: string | null | undefined, lastName?: string | null | undefined, phoneNumber?: string | null | undefined, postalCode?: string | null | undefined, province?: string | null | undefined, street?: string | null | undefined, suburb?: string | null | undefined, type: Types.AddressType, archivedAt?: any | null | undefined, hash: string } } };

export type CollectionPointCreateMutationVariables = Types.Exact<{
  data: Types.CreateCollectionPointInput;
}>;


export type CollectionPointCreateMutation = { __typename: 'Mutation', createCollectionPoint: { __typename: 'CollectionPointObject', id: number, name: string, code: string, enabled: boolean, createdAt: any, updatedAt: any, method: { __typename: 'ShippingMethodObject', id: number, name: string, code: string, slotRequired: boolean, collectionRequired: boolean, provider: string, requiresWaybill: boolean, timezone: string, color?: string | null | undefined, enabled: boolean }, address: { __typename: 'AddressObject', id: number, alias?: string | null | undefined, businessName?: string | null | undefined, city?: string | null | undefined, complex?: string | null | undefined, coordinates?: any | null | undefined, country: string, email?: string | null | undefined, firstName?: string | null | undefined, instructions?: string | null | undefined, lastName?: string | null | undefined, phoneNumber?: string | null | undefined, postalCode?: string | null | undefined, province?: string | null | undefined, street?: string | null | undefined, suburb?: string | null | undefined, type: Types.AddressType, archivedAt?: any | null | undefined, hash: string } } };

export const CollectionPointItemFragmentDoc = gql`
    fragment CollectionPointItem on CollectionPointObject {
  id
  name
  code
  method {
    ...ShippingMethodItem
  }
  enabled
  createdAt
  updatedAt
}
    ${ShippingMethodItemFragmentDoc}`;
export const CollectionPointRowFragmentDoc = gql`
    fragment CollectionPointRow on CollectionPointObject {
  id
  name
  code
  method {
    ...ShippingMethodItem
  }
  enabled
  createdAt
  updatedAt
  address {
    ...address
  }
}
    ${ShippingMethodItemFragmentDoc}
${AddressFragmentDoc}`;
export const CollectionPointItemDocument = gql`
    query CollectionPointItem($id: Int!) {
  collectionPoint(id: $id) {
    ...CollectionPointItem
  }
}
    ${CollectionPointItemFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class CollectionPointItemGQL extends Apollo.Query<CollectionPointItemQuery, CollectionPointItemQueryVariables> {
    document = CollectionPointItemDocument;
    client = 'ecommerce';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CollectionPointItemsDocument = gql`
    query CollectionPointItems($page: PageInput, $filter: CollectionPointFilterInput, $sort: SortInput) {
  items: collectionPoints(page: $page, filter: $filter, sort: $sort) {
    ...CollectionPointItem
  }
  totalItemsCount: collectionPointsCount(filter: $filter)
}
    ${CollectionPointItemFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class CollectionPointItemsGQL extends Apollo.Query<CollectionPointItemsQuery, CollectionPointItemsQueryVariables> {
    document = CollectionPointItemsDocument;
    client = 'ecommerce';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CollectionPointRowDocument = gql`
    query CollectionPointRow($id: Int!) {
  collectionPoint(id: $id) {
    ...CollectionPointRow
  }
}
    ${CollectionPointRowFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class CollectionPointRowGQL extends Apollo.Query<CollectionPointRowQuery, CollectionPointRowQueryVariables> {
    document = CollectionPointRowDocument;
    client = 'ecommerce';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CollectionPointRowsDocument = gql`
    query CollectionPointRows($page: PageInput, $filter: CollectionPointFilterInput, $sort: SortInput) {
  items: collectionPoints(page: $page, filter: $filter, sort: $sort) {
    ...CollectionPointRow
  }
  totalItemsCount: collectionPointsCount(filter: $filter)
}
    ${CollectionPointRowFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class CollectionPointRowsGQL extends Apollo.Query<CollectionPointRowsQuery, CollectionPointRowsQueryVariables> {
    document = CollectionPointRowsDocument;
    client = 'ecommerce';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CollectionPointUpdateDocument = gql`
    mutation CollectionPointUpdate($data: UpdateCollectionPointInput!) {
  updateCollectionPoint(data: $data) {
    ...CollectionPointRow
  }
}
    ${CollectionPointRowFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class CollectionPointUpdateGQL extends Apollo.Mutation<CollectionPointUpdateMutation, CollectionPointUpdateMutationVariables> {
    document = CollectionPointUpdateDocument;
    client = 'ecommerce';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CollectionPointCreateDocument = gql`
    mutation CollectionPointCreate($data: CreateCollectionPointInput!) {
  createCollectionPoint(data: $data) {
    ...CollectionPointRow
  }
}
    ${CollectionPointRowFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class CollectionPointCreateGQL extends Apollo.Mutation<CollectionPointCreateMutation, CollectionPointCreateMutationVariables> {
    document = CollectionPointCreateDocument;
    client = 'ecommerce';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }