import { Injectable } from '@angular/core';
import { mapFetchResultData } from '@x/common/graph';
import { CredentialsInput, ForgotPasswordInput } from '@x/schemas/ecommerce';
import { Observable } from 'rxjs';
import {
  CurrentUserGQL,
  ForgotPasswordGQL,
  LoginSsoUserGQL,
  LoginUserGQL,
} from './gql/auth.gql.generated';
import { IAuthUserDetail, ILoginObject } from './types/auth';

@Injectable()
export class AuthService {
  constructor(
    private loginUserGQL: LoginUserGQL,
    private loginSsoGQL: LoginSsoUserGQL,
    private forgotPasswordGQL: ForgotPasswordGQL,
    private currentUserGQL: CurrentUserGQL,
  ) {}

  loginUser(credentials: CredentialsInput): Observable<ILoginObject> {
    return this.loginUserGQL.mutate({ credentials }).pipe(mapFetchResultData((r) => r.loginUser));
  }

  loginSsoUser(idToken: string): Observable<ILoginObject> {
    return this.loginSsoGQL.mutate({ idToken }).pipe(mapFetchResultData((r) => r.loginSSOUser));
  }

  forgotPassword(credentials: ForgotPasswordInput) {
    return this.forgotPasswordGQL
      .mutate({ credentials })
      .pipe(mapFetchResultData((r) => r.forgotPassword));
  }

  currentUser(): Observable<IAuthUserDetail> {
    return this.currentUserGQL.fetch().pipe(mapFetchResultData((r) => r.authUser));
  }
}
