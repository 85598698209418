import { Injectable } from '@angular/core';
import { StockAllocationDaysFilterInput } from '@x/schemas/ecommerce';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { StockAllocationDaysGQL } from './gql/stock-allocation.gql.generated';
import { IStockAllocationDayItem } from './types/stock-allocation';

@Injectable()
export class StockAllocationService {
  constructor(private stockAllocationIntervalGQL: StockAllocationDaysGQL) {}

  fetchAllocationDays(
    filter: StockAllocationDaysFilterInput,
  ): Observable<IStockAllocationDayItem[]> {
    return this.stockAllocationIntervalGQL
      .fetch({ filter })
      .pipe(map((r) => r.data.stockAllocationDays));
  }
}
