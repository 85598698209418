import { Injectable } from '@angular/core';
import { IDataCollection, IDataCollectionProvider, IDataQuery } from '@x/common/data';
import { StockLotFilterInput } from '@x/schemas/ecommerce';
import { Observable } from 'rxjs';
import { StockLotService } from '../../domain-client/services/stock-lot.service';
import { IStockLotRow } from '../../domain-client/services/types/stock-lot';

@Injectable({ providedIn: 'root' })
export class StockLotRowDataProvider
  implements IDataCollectionProvider<IStockLotRow, StockLotFilterInput, unknown, string>
{
  constructor(private stockLotService: StockLotService) {}

  fetchCollection({
    filter,
    sort,
    page,
  }: Readonly<IDataQuery<StockLotFilterInput, unknown>>): Observable<
    IDataCollection<IStockLotRow>
  > {
    return this.stockLotService.fetchRows({
      filter: { ...filter },
      sort,
      page,
    });
  }

  fetchSingle(id: string): Observable<IStockLotRow> {
    return this.stockLotService.fetchItem(id);
  }

  toId(model: IStockLotRow): string {
    return model.id;
  }

  toString(model: IStockLotRow): string {
    return `${model.id}`;
  }
}
