import * as Types from '@x/schemas/ecommerce';

import { gql } from 'apollo-angular';
import { ChannelItemFragmentDoc, ChannelDetailFragmentDoc } from './channel.gql.generated';
import { AuthRoleDetailFragmentDoc } from './auth-role.gql.generated';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type AuthUserDetailFragment = { __typename: 'UserObject', id: number, firstName: string, lastName: string, phone?: string | null | undefined, email: string, birthday?: any | null | undefined, gender?: string | null | undefined, createdAt: any, updatedAt: any, defaultLocale?: string | null | undefined, defaultChannel?: { __typename: 'ChannelObject', id: number, code: string, name: string, color: string } | null | undefined, roles: Array<{ __typename: 'AuthRoleObject', id: number, name: string, code: string, permissions: Array<string>, createdAt: any, updatedAt: any, allChannels: boolean, channels: Array<{ __typename: 'ChannelObject', id: number }> }> };

export type LoginUserMutationVariables = Types.Exact<{
  credentials: Types.CredentialsInput;
}>;


export type LoginUserMutation = { __typename: 'Mutation', loginUser: { __typename: 'LoginObject', token: string, expiresAt: any, issuedAt: any, userId: number } };

export type LoginSsoUserMutationVariables = Types.Exact<{
  idToken: Types.Scalars['String']['input'];
}>;


export type LoginSsoUserMutation = { __typename: 'Mutation', loginSSOUser: { __typename: 'LoginObject', token: string, expiresAt: any, issuedAt: any, userId: number } };

export type ForgotPasswordMutationVariables = Types.Exact<{
  credentials: Types.ForgotPasswordInput;
}>;


export type ForgotPasswordMutation = { __typename: 'Mutation', forgotPassword: { __typename: 'ForgotPasswordObject', email: string } };

export type CurrentUserQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type CurrentUserQuery = { __typename: 'Query', authUser: { __typename: 'UserObject', id: number, firstName: string, lastName: string, phone?: string | null | undefined, email: string, birthday?: any | null | undefined, gender?: string | null | undefined, createdAt: any, updatedAt: any, defaultLocale?: string | null | undefined, defaultChannel?: { __typename: 'ChannelObject', id: number, code: string, name: string, color: string } | null | undefined, roles: Array<{ __typename: 'AuthRoleObject', id: number, name: string, code: string, permissions: Array<string>, createdAt: any, updatedAt: any, allChannels: boolean, channels: Array<{ __typename: 'ChannelObject', id: number }> }> } };

export const AuthUserDetailFragmentDoc = gql`
    fragment AuthUserDetail on UserObject {
  id
  firstName
  lastName
  phone
  email
  birthday
  gender
  createdAt
  updatedAt
  defaultChannel {
    ...ChannelItem
  }
  defaultLocale
  roles {
    ...AuthRoleDetail
  }
}
    ${ChannelItemFragmentDoc}
${AuthRoleDetailFragmentDoc}`;
export const LoginUserDocument = gql`
    mutation LoginUser($credentials: CredentialsInput!) {
  loginUser(credentials: $credentials) {
    token
    expiresAt
    issuedAt
    userId
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class LoginUserGQL extends Apollo.Mutation<LoginUserMutation, LoginUserMutationVariables> {
    document = LoginUserDocument;
    client = 'ecommerce';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const LoginSsoUserDocument = gql`
    mutation LoginSSOUser($idToken: String!) {
  loginSSOUser(idToken: $idToken) {
    token
    expiresAt
    issuedAt
    userId
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class LoginSsoUserGQL extends Apollo.Mutation<LoginSsoUserMutation, LoginSsoUserMutationVariables> {
    document = LoginSsoUserDocument;
    client = 'ecommerce';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ForgotPasswordDocument = gql`
    mutation ForgotPassword($credentials: ForgotPasswordInput!) {
  forgotPassword(credentials: $credentials) {
    email
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ForgotPasswordGQL extends Apollo.Mutation<ForgotPasswordMutation, ForgotPasswordMutationVariables> {
    document = ForgotPasswordDocument;
    client = 'ecommerce';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CurrentUserDocument = gql`
    query CurrentUser {
  authUser {
    ...AuthUserDetail
  }
}
    ${AuthUserDetailFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class CurrentUserGQL extends Apollo.Query<CurrentUserQuery, CurrentUserQueryVariables> {
    document = CurrentUserDocument;
    client = 'ecommerce';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }