import * as Types from '@x/schemas/ecommerce';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type PaymentItemFragment = { __typename: 'PaymentObject', id: number, state: Types.PaymentState, canCancel: boolean, canRefund: boolean, description: string, errorDescription?: string | null | undefined, amount: number, refundedAmount: number, currency: string, save: boolean, finalizedAt?: any | null | undefined, refundedAt?: any | null | undefined, createdAt: any, updatedAt: any, reference: string, method: { __typename: 'PaymentMethodObject', id: number, name: string, code: string, provider: string }, userMethod?: { __typename: 'UserPaymentMethodObject', id: number } | null | undefined, order: { __typename: 'OrderObject', currency: string } };

export type PaymentDetailFragment = { __typename: 'PaymentObject', id: number, state: Types.PaymentState, canCancel: boolean, canRefund: boolean, description: string, errorDescription?: string | null | undefined, publicAttributes?: any | null | undefined, amount: number, refundedAmount: number, currency: string, save: boolean, finalizedAt?: any | null | undefined, refundedAt?: any | null | undefined, createdAt: any, updatedAt: any, reference: string, method: { __typename: 'PaymentMethodObject', id: number, name: string, code: string, provider: string }, userMethod?: { __typename: 'UserPaymentMethodObject', id: number } | null | undefined, order: { __typename: 'OrderObject', currency: string } };

export type PaymentByIdQueryVariables = Types.Exact<{
  id: Types.Scalars['Int']['input'];
}>;


export type PaymentByIdQuery = { __typename: 'Query', payment: { __typename: 'PaymentObject', id: number, state: Types.PaymentState, canCancel: boolean, canRefund: boolean, description: string, errorDescription?: string | null | undefined, publicAttributes?: any | null | undefined, amount: number, refundedAmount: number, currency: string, save: boolean, finalizedAt?: any | null | undefined, refundedAt?: any | null | undefined, createdAt: any, updatedAt: any, reference: string, method: { __typename: 'PaymentMethodObject', id: number, name: string, code: string, provider: string }, userMethod?: { __typename: 'UserPaymentMethodObject', id: number } | null | undefined, order: { __typename: 'OrderObject', currency: string } } };

export type OrderPaymentsQueryVariables = Types.Exact<{
  id: Types.Scalars['Int']['input'];
}>;


export type OrderPaymentsQuery = { __typename: 'Query', order: { __typename: 'OrderObject', payments: Array<{ __typename: 'PaymentObject', id: number, state: Types.PaymentState, canCancel: boolean, canRefund: boolean, description: string, errorDescription?: string | null | undefined, publicAttributes?: any | null | undefined, amount: number, refundedAmount: number, currency: string, save: boolean, finalizedAt?: any | null | undefined, refundedAt?: any | null | undefined, createdAt: any, updatedAt: any, reference: string, method: { __typename: 'PaymentMethodObject', id: number, name: string, code: string, provider: string }, userMethod?: { __typename: 'UserPaymentMethodObject', id: number } | null | undefined, order: { __typename: 'OrderObject', currency: string } }> } };

export type CheckCartPaymentBalanceQueryVariables = Types.Exact<{
  input: Types.RedeemCartPaymentInput;
}>;


export type CheckCartPaymentBalanceQuery = { __typename: 'Query', checkCartPaymentBalance: { __typename: 'VoucherPaymentBalanceObject', currency: string, amount: number } };

export type RedeemCartPaymentMutationVariables = Types.Exact<{
  input: Types.RedeemCartPaymentInput;
}>;


export type RedeemCartPaymentMutation = { __typename: 'Mutation', redeemCartPayment: { __typename: 'PaymentObject', id: number, state: Types.PaymentState, canCancel: boolean, canRefund: boolean, description: string, errorDescription?: string | null | undefined, publicAttributes?: any | null | undefined, amount: number, refundedAmount: number, currency: string, save: boolean, finalizedAt?: any | null | undefined, refundedAt?: any | null | undefined, createdAt: any, updatedAt: any, reference: string, method: { __typename: 'PaymentMethodObject', id: number, name: string, code: string, provider: string }, userMethod?: { __typename: 'UserPaymentMethodObject', id: number } | null | undefined, order: { __typename: 'OrderObject', currency: string } } };

export type RequestPaymentMutationVariables = Types.Exact<{
  input: Types.RequestCartPaymentInput;
}>;


export type RequestPaymentMutation = { __typename: 'Mutation', requestCartPayment: { __typename: 'PaymentObject', id: number, state: Types.PaymentState, canCancel: boolean, canRefund: boolean, description: string, errorDescription?: string | null | undefined, publicAttributes?: any | null | undefined, amount: number, refundedAmount: number, currency: string, save: boolean, finalizedAt?: any | null | undefined, refundedAt?: any | null | undefined, createdAt: any, updatedAt: any, reference: string, method: { __typename: 'PaymentMethodObject', id: number, name: string, code: string, provider: string }, userMethod?: { __typename: 'UserPaymentMethodObject', id: number } | null | undefined, order: { __typename: 'OrderObject', currency: string } } };

export type RequestNewPaymentMutationVariables = Types.Exact<{
  input: Types.RequestNewCartPaymentInput;
}>;


export type RequestNewPaymentMutation = { __typename: 'Mutation', requestNewCartPayment: { __typename: 'PaymentObject', id: number, state: Types.PaymentState, canCancel: boolean, canRefund: boolean, description: string, errorDescription?: string | null | undefined, publicAttributes?: any | null | undefined, amount: number, refundedAmount: number, currency: string, save: boolean, finalizedAt?: any | null | undefined, refundedAt?: any | null | undefined, createdAt: any, updatedAt: any, reference: string, method: { __typename: 'PaymentMethodObject', id: number, name: string, code: string, provider: string }, userMethod?: { __typename: 'UserPaymentMethodObject', id: number } | null | undefined, order: { __typename: 'OrderObject', currency: string } } };

export type ReconcilePaymentQueryVariables = Types.Exact<{
  input: Types.ReconCartPaymentInput;
}>;


export type ReconcilePaymentQuery = { __typename: 'Query', reconCartPayment: { __typename: 'PaymentObject', id: number, state: Types.PaymentState, canCancel: boolean, canRefund: boolean, description: string, errorDescription?: string | null | undefined, publicAttributes?: any | null | undefined, amount: number, refundedAmount: number, currency: string, save: boolean, finalizedAt?: any | null | undefined, refundedAt?: any | null | undefined, createdAt: any, updatedAt: any, reference: string, method: { __typename: 'PaymentMethodObject', id: number, name: string, code: string, provider: string }, userMethod?: { __typename: 'UserPaymentMethodObject', id: number } | null | undefined, order: { __typename: 'OrderObject', currency: string } } };

export type CancelPaymentMutationVariables = Types.Exact<{
  input: Types.CancelCartPaymentInput;
}>;


export type CancelPaymentMutation = { __typename: 'Mutation', cancelCartPayment: { __typename: 'PaymentObject', id: number, state: Types.PaymentState, canCancel: boolean, canRefund: boolean, description: string, errorDescription?: string | null | undefined, publicAttributes?: any | null | undefined, amount: number, refundedAmount: number, currency: string, save: boolean, finalizedAt?: any | null | undefined, refundedAt?: any | null | undefined, createdAt: any, updatedAt: any, reference: string, method: { __typename: 'PaymentMethodObject', id: number, name: string, code: string, provider: string }, userMethod?: { __typename: 'UserPaymentMethodObject', id: number } | null | undefined, order: { __typename: 'OrderObject', currency: string } } };

export type RefundPaymentMutationVariables = Types.Exact<{
  input: Types.RefundCartPaymentInput;
}>;


export type RefundPaymentMutation = { __typename: 'Mutation', refundCartPayment: { __typename: 'PaymentObject', id: number, state: Types.PaymentState, canCancel: boolean, canRefund: boolean, description: string, errorDescription?: string | null | undefined, publicAttributes?: any | null | undefined, amount: number, refundedAmount: number, currency: string, save: boolean, finalizedAt?: any | null | undefined, refundedAt?: any | null | undefined, createdAt: any, updatedAt: any, reference: string, method: { __typename: 'PaymentMethodObject', id: number, name: string, code: string, provider: string }, userMethod?: { __typename: 'UserPaymentMethodObject', id: number } | null | undefined, order: { __typename: 'OrderObject', currency: string } } };

export type CreateManualPaymentMutationVariables = Types.Exact<{
  input: Types.CreateManualPaymentInput;
}>;


export type CreateManualPaymentMutation = { __typename: 'Mutation', createManualPayment: { __typename: 'PaymentObject', id: number, state: Types.PaymentState, canCancel: boolean, canRefund: boolean, description: string, errorDescription?: string | null | undefined, publicAttributes?: any | null | undefined, amount: number, refundedAmount: number, currency: string, save: boolean, finalizedAt?: any | null | undefined, refundedAt?: any | null | undefined, createdAt: any, updatedAt: any, reference: string, method: { __typename: 'PaymentMethodObject', id: number, name: string, code: string, provider: string }, userMethod?: { __typename: 'UserPaymentMethodObject', id: number } | null | undefined, order: { __typename: 'OrderObject', currency: string } } };

export type PaymentErrorDescriptionsQueryVariables = Types.Exact<{
  filter: Types.PaymentErrorFilterInput;
  page?: Types.InputMaybe<Types.PageInput>;
}>;


export type PaymentErrorDescriptionsQuery = { __typename: 'Query', paymentErrorDescriptions: Array<string> };

export const PaymentItemFragmentDoc = gql`
    fragment PaymentItem on PaymentObject {
  id
  state
  canCancel
  canRefund
  description
  errorDescription
  amount
  refundedAmount
  currency
  save
  canRefund
  method {
    id
    name
    code
    provider
  }
  userMethod {
    id
  }
  order {
    currency
  }
  finalizedAt
  refundedAt
  createdAt
  updatedAt
  reference
}
    `;
export const PaymentDetailFragmentDoc = gql`
    fragment PaymentDetail on PaymentObject {
  id
  state
  canCancel
  canRefund
  description
  errorDescription
  publicAttributes
  amount
  refundedAmount
  currency
  save
  canRefund
  method {
    id
    name
    code
    provider
  }
  userMethod {
    id
  }
  order {
    currency
  }
  finalizedAt
  refundedAt
  createdAt
  updatedAt
  reference
}
    `;
export const PaymentByIdDocument = gql`
    query PaymentById($id: Int!) {
  payment(id: $id) {
    ...PaymentDetail
  }
}
    ${PaymentDetailFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class PaymentByIdGQL extends Apollo.Query<PaymentByIdQuery, PaymentByIdQueryVariables> {
    document = PaymentByIdDocument;
    client = 'ecommerce';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const OrderPaymentsDocument = gql`
    query OrderPayments($id: Int!) {
  order(id: $id) {
    payments {
      ...PaymentDetail
    }
  }
}
    ${PaymentDetailFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class OrderPaymentsGQL extends Apollo.Query<OrderPaymentsQuery, OrderPaymentsQueryVariables> {
    document = OrderPaymentsDocument;
    client = 'ecommerce';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CheckCartPaymentBalanceDocument = gql`
    query CheckCartPaymentBalance($input: RedeemCartPaymentInput!) {
  checkCartPaymentBalance(input: $input) {
    currency
    amount
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CheckCartPaymentBalanceGQL extends Apollo.Query<CheckCartPaymentBalanceQuery, CheckCartPaymentBalanceQueryVariables> {
    document = CheckCartPaymentBalanceDocument;
    client = 'ecommerce';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const RedeemCartPaymentDocument = gql`
    mutation RedeemCartPayment($input: RedeemCartPaymentInput!) {
  redeemCartPayment(input: $input) {
    ...PaymentDetail
  }
}
    ${PaymentDetailFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class RedeemCartPaymentGQL extends Apollo.Mutation<RedeemCartPaymentMutation, RedeemCartPaymentMutationVariables> {
    document = RedeemCartPaymentDocument;
    client = 'ecommerce';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const RequestPaymentDocument = gql`
    mutation RequestPayment($input: RequestCartPaymentInput!) {
  requestCartPayment(input: $input) {
    ...PaymentDetail
  }
}
    ${PaymentDetailFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class RequestPaymentGQL extends Apollo.Mutation<RequestPaymentMutation, RequestPaymentMutationVariables> {
    document = RequestPaymentDocument;
    client = 'ecommerce';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const RequestNewPaymentDocument = gql`
    mutation RequestNewPayment($input: RequestNewCartPaymentInput!) {
  requestNewCartPayment(input: $input) {
    ...PaymentDetail
  }
}
    ${PaymentDetailFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class RequestNewPaymentGQL extends Apollo.Mutation<RequestNewPaymentMutation, RequestNewPaymentMutationVariables> {
    document = RequestNewPaymentDocument;
    client = 'ecommerce';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ReconcilePaymentDocument = gql`
    query ReconcilePayment($input: ReconCartPaymentInput!) {
  reconCartPayment(input: $input) {
    ...PaymentDetail
  }
}
    ${PaymentDetailFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ReconcilePaymentGQL extends Apollo.Query<ReconcilePaymentQuery, ReconcilePaymentQueryVariables> {
    document = ReconcilePaymentDocument;
    client = 'ecommerce';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CancelPaymentDocument = gql`
    mutation CancelPayment($input: CancelCartPaymentInput!) {
  cancelCartPayment(input: $input) {
    ...PaymentDetail
  }
}
    ${PaymentDetailFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class CancelPaymentGQL extends Apollo.Mutation<CancelPaymentMutation, CancelPaymentMutationVariables> {
    document = CancelPaymentDocument;
    client = 'ecommerce';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const RefundPaymentDocument = gql`
    mutation RefundPayment($input: RefundCartPaymentInput!) {
  refundCartPayment(input: $input) {
    ...PaymentDetail
  }
}
    ${PaymentDetailFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class RefundPaymentGQL extends Apollo.Mutation<RefundPaymentMutation, RefundPaymentMutationVariables> {
    document = RefundPaymentDocument;
    client = 'ecommerce';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateManualPaymentDocument = gql`
    mutation CreateManualPayment($input: CreateManualPaymentInput!) {
  createManualPayment(input: $input) {
    ...PaymentDetail
  }
}
    ${PaymentDetailFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateManualPaymentGQL extends Apollo.Mutation<CreateManualPaymentMutation, CreateManualPaymentMutationVariables> {
    document = CreateManualPaymentDocument;
    client = 'ecommerce';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const PaymentErrorDescriptionsDocument = gql`
    query PaymentErrorDescriptions($filter: PaymentErrorFilterInput!, $page: PageInput) {
  paymentErrorDescriptions(filter: $filter, page: $page)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class PaymentErrorDescriptionsGQL extends Apollo.Query<PaymentErrorDescriptionsQuery, PaymentErrorDescriptionsQueryVariables> {
    document = PaymentErrorDescriptionsDocument;
    client = 'ecommerce';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }