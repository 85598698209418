import * as Types from '@x/schemas/ecommerce';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type StockAllocationDayItemFragment = { __typename: 'StockAllocationDayObject', date: string, orderQuantities: { __typename: 'StockAllocationOrderQuantitiesObject', unallocated: number, reserved: number, allocated: number, packed: number, cancelled: number }, lotQuantities: { __typename: 'StockAllocationLotQuantitiesObject', quantity?: number | null | undefined, reserved: number, allocated: number }, item: { __typename: 'StockItemObject', id: number, sku: string }, warehouse: { __typename: 'WarehouseObject', id: number, name: string, color?: string | null | undefined } };

export type StockAllocationDaysQueryVariables = Types.Exact<{
  filter: Types.StockAllocationDaysFilterInput;
}>;


export type StockAllocationDaysQuery = { __typename: 'Query', stockAllocationDays: Array<{ __typename: 'StockAllocationDayObject', date: string, orderQuantities: { __typename: 'StockAllocationOrderQuantitiesObject', unallocated: number, reserved: number, allocated: number, packed: number, cancelled: number }, lotQuantities: { __typename: 'StockAllocationLotQuantitiesObject', quantity?: number | null | undefined, reserved: number, allocated: number }, item: { __typename: 'StockItemObject', id: number, sku: string }, warehouse: { __typename: 'WarehouseObject', id: number, name: string, color?: string | null | undefined } }> };

export const StockAllocationDayItemFragmentDoc = gql`
    fragment StockAllocationDayItem on StockAllocationDayObject {
  date
  orderQuantities {
    unallocated
    reserved
    allocated
    packed
    cancelled
  }
  lotQuantities {
    quantity
    reserved
    allocated
  }
  item {
    id
    sku
  }
  warehouse {
    id
    name
    color
  }
}
    `;
export const StockAllocationDaysDocument = gql`
    query StockAllocationDays($filter: StockAllocationDaysFilterInput!) {
  stockAllocationDays(filter: $filter) {
    ...StockAllocationDayItem
  }
}
    ${StockAllocationDayItemFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class StockAllocationDaysGQL extends Apollo.Query<StockAllocationDaysQuery, StockAllocationDaysQueryVariables> {
    document = StockAllocationDaysDocument;
    client = 'ecommerce';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }