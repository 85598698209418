import { Injectable } from '@angular/core';
import { mapFetchResultData } from '@x/common/graph';
import { CreateStockLotInput, UpdateStockLotInput } from '@x/schemas/ecommerce';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  AdjustStockLotGQL,
  CreateStockLotGQL,
  StockLotDetailGQL,
  StockLotRowGQL,
  StockLotsGQL,
  StockLotsQueryVariables,
  UpdateEnabledStockLotGQL,
  UpdateStockLotGQL,
} from './gql/stock-lot.gql.generated';
import { IStockLotDetail, IStockLotItem } from './types/stock-lot';

@Injectable()
export class StockLotService {
  constructor(
    private readonly stockLotsGQL: StockLotsGQL,
    private readonly stockLotRowGQL: StockLotRowGQL,
    private readonly refDetailGQL: StockLotDetailGQL,
    private readonly createGQL: CreateStockLotGQL,
    private readonly updateGQL: UpdateStockLotGQL,
    private readonly updateEnabledStockLotGQL: UpdateEnabledStockLotGQL,
    private readonly AdjustStockLotGQL: AdjustStockLotGQL,
  ) {}

  fetchDetail(id: string): Observable<IStockLotDetail> {
    return this.refDetailGQL.fetch({ id }).pipe(map((r) => r.data.stockLot));
  }

  fetchItem(id: string): Observable<IStockLotItem> {
    return this.stockLotRowGQL.fetch({ id }).pipe(mapFetchResultData((r) => r.stockLot));
  }

  fetchRows(
    query: StockLotsQueryVariables,
  ): Observable<{ items: IStockLotItem[]; totalItemsCount: number }> {
    return this.stockLotsGQL.fetch(query).pipe(map((r) => r.data));
  }

  create(input: CreateStockLotInput) {
    return this.createGQL.mutate({ input }).pipe(mapFetchResultData((r) => r.createStockLot));
  }

  update(input: UpdateStockLotInput) {
    return this.updateGQL.mutate({ input }).pipe(mapFetchResultData((r) => r.updateStockLot));
  }

  updateEnabled(ref: string, enabled: boolean) {
    return this.updateEnabledStockLotGQL
      .mutate({ ref, enabled })
      .pipe(mapFetchResultData((r) => r.updateStockLotEnabled));
  }

  adjust(ref: string, adjustment: number) {
    return this.AdjustStockLotGQL.mutate({ ref, adjustment }).pipe(
      mapFetchResultData((r) => r.adjustStockLot),
    );
  }
}
